import "./App.css";
import { Sceenshot } from "./components";

export default function App() {
  return (
    <main className="dark:bg-black dark:text-white bg-white text-black min-h-[100vh]">
      <div className="p-20">
        <Sceenshot />
      </div>
    </main>
  );
}
