import { useState } from "react";
import html2canvas from "html2canvas";
import { storage, database } from "../lib/appwrite";
import { ID } from "appwrite";

export function Sceenshot() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);

    const takeScreenshot = () => {
        html2canvas(document.body).then((canvas) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    uploadScreenshot(new File([blob], "screenshot.png"));
                }
            });
        });
    };

    async function uploadScreenshot(file: File) {
        setLoading(true);
        try {
            const data = await storage.createFile(
                "66508c7e000971ac2cf1",
                ID.unique(),
                file
            );
            if (data?.$id) {
                const preview = storage.getFilePreview(
                    "66508c7e000971ac2cf1",
                    data.$id
                );
                if (preview?.href) {
                    await database.createDocument(
                        "66508be7003e6c0501a8",
                        "66508bf300075c6ec771",
                        ID.unique(),
                        {
                            url: preview.href,
                            created: new Date().toISOString(),
                        }
                    );
                    getData();
                }
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    async function getData() {
        const data = await database.listDocuments(
            "66508be7003e6c0501a8",
            "66508bf300075c6ec771"
        );
        setData(data?.documents);
    }

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">Take a Screenshot</h1>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-5"
                onClick={takeScreenshot}
                disabled={loading}
            >
                Take Screenshot
            </button>

            {loading && <p>Uploading...</p>}

            {data && data.length > 0 ? (
                <div className="grid grid-cols-3 gap-5">
                    {data.map((item) => (
                        <img
                            key={item.$id}
                            src={item.url}
                            alt="screenshot"
                            className="border cursor-pointer"
                            onClick={() => window.open(item.url, "_blank")}
                        />
                    ))}
                </div>
            ) : (
                <p>No screenshots</p>
            )}
        </div>
    );
}
